import React from 'react';
import {
  DontDo,
  DontDoItem,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformExceptionItem,
  PlatformExceptionList,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Shadows"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Layering"
        tierThree="Shadows"
      />

      <PageNavigation>
        <PageNavigationLink>Layers</PageNavigationLink>
        <PageNavigationLink>Mobile</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Layers">
        <Paragraph>
          <Link href="/guidelines/layering/elevation" isDesignCodeLink>
            Layers
          </Link>{' '}
          place interface content “over” other content. While this can obstruct
          content, it increases the available area for additional content
          without leaving the current context. If we apply shadows at different
          levels, we can simulate a three-dimensional stack of layers.
        </Paragraph>
      </Section>

      <Section title="Mobile">
        <Paragraph>
          Shadows differ between platforms. Keep a close eye on how layering and
          elevation affect shadows on both.
        </Paragraph>
        <PlatformExceptionList>
          <PlatformExceptionItem platform="apple">
            Shadows should be used subtly and minimally throughout an iOS
            interface. Depth can display layers in order without using shadows
            with touch gestures and motion instead.
          </PlatformExceptionItem>
          <PlatformExceptionItem platform="android">
            Layering is closely tied to{' '}
            <Link href="https://material.io/design/environment/elevation.html#depicting-elevation">
              shadow and elevation
            </Link>{' '}
            for Android design, and should maintain that relationship using
            Uniform shadow values.
          </PlatformExceptionItem>
        </PlatformExceptionList>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Application</SectionSubhead>
        <List>
          <li>
            The first elevation shadow is meant for elements that are still
            somehow “connected” to a parent element, such as dropdowns or
            popovers.
          </li>
          <li>
            Alerts, like{' '}
            <Link href="/components/toast" isDesignCodeLink>
              toasts
            </Link>
            , receive the second elevation shadow–they typically don’t block an
            interface, but are still disconnected from any main interface
            element.
          </li>
          <li>
            When blocking an interface entirely with something like a{' '}
            <Link href="/components/modal" isDesignCodeLink>
              modal
            </Link>
            , the third elevation shadow should be used.
          </li>
        </List>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="mismatch shadows and layers."
            img="shadows-application-dont"
          />
          <DontDoItem
            type="do"
            text="apply the correct shadow to its matching layer."
            img="shadows-application-do"
          />
        </DontDo>

        <SectionSubhead>Layout</SectionSubhead>
        <Paragraph>
          Shadows are not meant to break up content on a page. Our{' '}
          <Link href="/components/themes/level" isDesignCodeLink>
            levels
          </Link>{' '}
          are built to handle that.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="put shadows on levels or dividers."
            img="shadows-layout-dont"
          />
          <DontDoItem
            type="do"
            text="use levels and accents to break up page content."
            img="shadows-layout-do"
          />
        </DontDo>
      </Section>

      <Section title="Platform">
        <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple">
            <List>
              <li>
                {/* eslint-disable max-len */}
                Follow the{' '}
                <Link href="https://developer.apple.com/ios/human-interface-guidelines/overview/">
                  HIG
                </Link>{' '}
                for individual interface elements.
                {/* eslint-enable max-len */}
              </li>
            </List>
          </PlatformTableRow>
          <PlatformTableRow platform="android">
            <List>
              <li>
                Follow the{' '}
                <Link href="https://material.io/guidelines/">
                  Material Guidelines
                </Link>{' '}
                for individual interface elements.
              </li>
            </List>
          </PlatformTableRow>
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
